import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import ElementPlus from "element-plus";
import 'element-plus/dist/index.css'
// createApp(App).use(ElementUI)
createApp(App).use(store).use(ElementPlus).use(router).mount("#app");
