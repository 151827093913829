
import { ElMessage } from "element-plus";

import { Options, Vue } from "vue-class-component";
import axios from "axios";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

@Options({
  components: {
    // HelloWorld,
  },
})
export default class Home extends Vue {
  // interface form{
  //   name:string;
  //   email:string;
  //   message:string
  // }
  //  obje : {
  name = "";
  email = "";
  message = "";
  // }

  // data() {
  //   return {
  //     name: "",
  //     email: "",
  //     message: "",
  //   };
  // }
  // methods: {
  // submit() {}
  // },
  // methods: {
  submit() {
    var name = this.name;
    var email = this.email;
    var message = this.message;
    if (!name) {
      ElMessage.error("姓名不能为空!");
      return;
    }
    if (!email) {
      ElMessage.error("邮箱不能为空!");
      return;
    }
    if (email) {
      var reg =
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
      // (!reg.test(email) && (rowerror.email = "请输入正确格式")) ||
      //   (rowerror.email = "");
      if (!reg.test(email)) {
        ElMessage.error("请输入正确格式!");
        return;
      }
    }
    if (!message) {
      ElMessage.error("留言不能为空!");
      return;
    }
    const obj = { name, email, message };
    axios
      .post("https://formspree.io/f/mjvlprkq", {
        data: obj,
      })
      .then((res) => {
        ElMessage({
          showClose: true,
          message: "资讯信息提交成功!",
          type: "success",
        });
        this.name = "";
        this.email = "";
        this.message = "";
        // console.log(res);
      });
    // if(this.name && this.email && this.message){
    // }
    // console.log(this.name, this.email, this.message);
  }
  // },
}
